<template>
    <BackgroundWaves color="#292827" />

    <div class="centered-container">
        <v-img class="login-img-logo" :src="LoginLogo"></v-img>
        <div class="welcome-text">
            <h1>Herzlich willkommen <br> zum GummiLove-Quiz!</h1>
        </div>

        <div>
            <v-card
                class="mx-auto pa-10 pt-8 bg-white"
                elevation="8"
                width="448"
                rounded="lg"
                variant="outlined"
            >
                <h2 class="text-center mb-7">Anmelden</h2>

                <v-form ref="LoginForm">
                    <v-text-field
                        v-model="form.email"
                        density="compact"
                        placeholder="E-Mail or Benutzername"
                        variant="outlined"
                        :rules="[ /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(form.email) || 'E-mail must be valid']"
                    ></v-text-field>

                    <v-text-field
                        v-model="form.password"
                        :append-inner-icon="visible ? 'visibility_off' : 'visibility'"
                        :type="visible ? 'text' : 'password'"
                        density="compact"
                        placeholder="Passwort"
                        variant="outlined"
                        @click:append-inner="visible = !visible"
                        :rules="[
                            password => !!password || 'Falsch'
                        ]"
                    ></v-text-field>

                    <div class="form-fields-container">
                        <div class="half-width">
                            <v-text-field
                                v-model="form.school"
                                density="compact"
                                placeholder="Schule"
                                variant="outlined"
                                class="half-width"
                            ></v-text-field>
                        </div>
                        <div class="half-width">
                            <v-text-field
                                v-model="form.class"
                                density="compact"
                                placeholder="Klasse"
                                variant="outlined"
                                class="half-width"
                            ></v-text-field>
                        </div>
                    </div>

                    <!--<v-row class="d-flex justify-space-between">
                        <v-col>
                            <v-checkbox true-icon="check_box" false-icon="check_box_outline_blank" label="Angemeldet bleiben" class="stay-logged-in"></v-checkbox>
                        </v-col>
                        <v-col>
                            <a class="password-forgot" href="#" rel="noopener noreferrer" target="_blank">
                                Passwort vergessen?
                            </a>
                        </v-col>
                    </v-row>-->

                    <v-btn
                        size="large"
                        variant="outlined"
                        rounded
                        block
                        :disabled="!isValid"
                        @click="login(form)"
                    >
                        Log in
                    </v-btn>
                </v-form>
            </v-card>
        </div>
    </div>
</template>

<script setup>
import LoginLogo from '/src/assets/LoginLogo.png';
import { ref, reactive, computed } from 'vue';
import BackgroundWaves from '@/components/BackgroundWavesComponent.vue';
// import { login } from "@/services/APIService";
import useAuth from '@/composables/useAuth';

const { login } = useAuth()

const form = reactive({
    email: '',
    password: '',
    school: '',
    class: ''
})

let LoginForm = ref(null);
let visible = ref(false);

// let email = ref('');
// let password = ref('');
// let school = ref('');
// let klasse = ref('');

/*
function LoginBtn() {
    login(email.value, password.value, school.value, klasse.value);
}
*/

/*
Zeile ':disabled="!isValid"' oberhalb des Klick-Events rausgenommen, korrespondiert mit der Funktion unten:
*/

const isValid = computed(() => {
    return form.email.trim() !== '' && form.password.trim() !== '' && form.school.trim() !== '' && form.class.trim() !== '';
});

</script>