import axios from "axios";
import { useAuthStore } from "@/stores/AuthStore";
import router from "@/router";

export async function getAllTopics() {
  try {
    const response = await axios.get("api/topic");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getAllQuestions() {
  try {
    const response = await axios.get("api/question");
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function getQuestionPerTopicId(id) {
  try {
    console.log(id);
    const response = await axios.get("api/question/" + id);
    return response.data;
  } catch (error) {
    console.error(error);
  }
}

export async function login(email, password) {
  const authStore = useAuthStore();
  axios
    .post("/login", {
      email: email,
      password: password,
    })
    .then((response) => {
      console.log("Hello");
      authStore.setToken(response.data.token);
      localStorage.setItem("token", response.data.token);
      console.log("Erfolgreich eingeloggt:", response.data);
      router.push({ name: "home" });
      // Optional: Speichere das Token, falls erforderlich
      localStorage.setItem("token", response.data.token);
    })
    .catch((error) => {
      console.error("Fehler beim Einloggen:", error);
    });
}
