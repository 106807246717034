<template>
  <v-app>
    <div class="container" v-if="authenticated && showButton">
      <p class="pWelcome">Hallo {{user.name}}!</p>
      <v-btn  @click="logout" style="width: auto;" size="x-small">
        Abmelden
      </v-btn>
    </div>
    <v-main>
      <router-view />
    </v-main>
  </v-app>
</template>

<script setup>
import { onMounted, watch, computed } from 'vue'
import router from "@/router";
import useAuth from './composables/useAuth';
import { useRoute } from 'vue-router';

const { authenticated, user, logout } = useAuth()

const route = useRoute(); // Aktuelle Route

watch(authenticated, (newValue) => {
  if (newValue) {
    console.log(newValue, "I push to Home");
    router.push({ name: 'home' });
    console.log("Hallo", user.value.name, "!")
  } else {
    console.log("value is false!");
    router.push({ name: 'login' })
  }
});

onMounted(() => {
  if (authenticated.value) {
    console.log(authenticated.value, "I push to Home");
    router.push({ name: 'home' });
    console.log("Hallo", user.value.name, "!")
  } else {
    console.log("value is false!");
  }
});

const showButton = computed(() => {
  console.log(route.name);
  return route.name === 'preview' || route.name === 'home';
});

/*
export default {
  name: 'App',

  data: () => ({
    // gjgjjh
  }),
}
*/

</script>

<style scoped>
.container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 15px;
  position: fixed;
  left: 0;
  right: 0;
  z-index: 2;
}
.pWelcome {
  color: #F4F4F4; 
  background-color: #292827;
  font-weight: bold;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-radius: 5px;
}
</style>
